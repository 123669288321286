<template>
    <div class="container-fluid content-wrapper" v-if="true">
            <div class="content-heading">Rental Management</div>
            <b-row class="pl-3 mb-4">
                <b-overlay
                    :show="isBusy"
                    rounded
                    opacity="0.5"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                >
                    <b-dropdown text="Export Report" variant="primary">
                        <b-dropdown-item @click="downloadFile('pdf')"
                            >PDF</b-dropdown-item
                        >
                        <b-dropdown-item @click="downloadFile('csv')"
                            >CSV</b-dropdown-item
                        >
                    </b-dropdown>
                </b-overlay>
            </b-row>

        <p-table
        :items="filteredTableData"
        :fields="fields"
        sort-by="authorTitleOrDescription"
        :enableRowSelection="false"
        :showPerPageSelector="true"
        :per-page="25"
        @updated-table-data="updatedTableData($event)"
    >
        <template v-slot:above-table>
                <b-row class="">
                    <b-col cols="3">
                        <p-advanced-select
                            dataType="terms"
                            :filter="filterTerm"
                            label="Term"
                            v-model="filters.termId"
                            @input="termChange($event)"
                        />
                    </b-col>
                    <b-col cols="3">
                        <p-select
                            :options="sourceOptions"
                            label="Sources"
                            v-model="filters.rentalSource"
                            :disabled="selectedTermId == null"
                        />
                    </b-col>
                </b-row>
            <!-- </p-form> -->
            <div class="mr-2">
                <p-card>
                    <b-row class="text-center">
                        <b-col>
                            <strong>Term</strong><br />
                            {{ termName }}
                        </b-col>
                        <b-col>
                            <strong>First Day to Rent</strong><br />
                            {{ dateFormatter(rentalData.startDate) }}
                        </b-col>
                        <b-col>
                            <strong>Last Day to Rent</strong><br />
                            {{ dateFormatter(rentalData.endDate) }}
                        </b-col>
                        <b-col>
                            <strong>Full Refund Expiration Date</strong><br />
                            {{ dateFormatter(rentalData.refundExpDate) }}
                        </b-col>
                        <b-col>
                            <strong>Due Date</strong><br />
                            {{ dateFormatter(rentalData.dueDate) }}
                        </b-col>
                    </b-row>
                </p-card>
            </div>
        </template>
        <template
            v-slot:cell(edit)="{
                value,
                item
            }"
        >
            <rental
                class="pb-2 ml-3 mr-2"
                :rentals="item"
                @data-saved="updateRentalSearch()"
                :disabled="!isOpenTerm"
            />
        </template>
        <template
            v-slot:cell(author)="{
                value,
                item
            }"
        >
            <div>{{ item.author + ' / ' + item.title + ' / '}}</div>
            <div>{{ item.isbn }}</div>
        </template>
        <template
            v-slot:cell(rentalSource)="{
                value,
                item
            }"
        >
            <div class="mt-2">
                {{ item.rentalSource }}
            </div>
        </template>

        <template
            v-slot:cell(newUsed)="{
                value,
                item
            }"
        >
            <div class="mt-2 ">
                New
            </div>
            <div class="mt-3 pt-2 ">
                Used
            </div>
        </template>
        <template
            v-slot:cell(newRentalPrice)="{
                value,
                item
            }"
        >
            <div class="mt-2">{{ currencyFormatter(item.newRentalPrice) }}</div>
            <div class="mt-3 pt-2">
                {{ currencyFormatter(item.usedRentalPrice) }}
            </div>
        </template>
        <template
            v-slot:cell(newPrice)="{
                value,
                item
            }"
        >
            <div class="mt-2">{{ currencyFormatter(item.newPrice) }}</div>
            <div class="mt-3 pt-2">{{ currencyFormatter(item.usedPrice) }}</div>
        </template>
        <template
            v-slot:cell(newCost)="{
                value,
                item
            }"
        >
            <div class="mt-2">{{ currencyFormatter(item.newCost) }}</div>
            <div class="mt-3 pt-2">{{ currencyFormatter(item.usedCost) }}</div>
        </template>
        <template
            v-slot:cell(defaultLateFee)="{
                value,
                item
            }"
        >
            <div class="mt-2">{{ currencyFormatter(item.defaultLateFee) }}</div>
            <div class="mt-3 pt-2">
                {{ currencyFormatter(item.defaultLateFee) }}
            </div>
        </template>

        <template
            v-slot:cell(rebatePrice)="{
                value,
                item
            }"
        >
            <div class="mt-2">{{ currencyFormatter(item.rebatePrice) }}</div>
            <div class="mt-3 pt-2">
                {{ currencyFormatter(item.rebatePrice) }}
            </div>
        </template>
        <template
            v-slot:cell(newIsActive)="{
                value,
                item
            }"
        >
            <div class="mt-2">
                {{ item.newIsActive ? 'Active' : 'Inactive' }}
            </div>
            <div class="mt-2 pt-2">
                {{ item.usedIsActive ? 'Active' : 'Inactive' }}
            </div>
        </template>
    </p-table>
    </div>
</template>

<script>
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import axios from 'axios';
import {
    dateFormatter,
    currencyFormatter
} from '@/components/Common/Formatters.js';

import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';

import StoreListManager from '@/components/mixins/StoreListManager.js';
import rental from '@/views/RentalManagement/Rental.vue';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [SearchStorage, StoreListManager],
    data() {
        return {
            filterName: 'RentalManagementFilter ',
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'termId',
                sortDesc: true,
                searchBy: 'All',
                startDate: null,
                endDate: null,
                termId: null,
                active: false,
                inactive: false,
                rentalSource: 'All Sources',
                exportFormat: null
            },
            isBusy: false,
            selectedTermId: null,
            isOpenTerm: null,
            isActiveTerm: null,
            filterBy: ['All', 'ISBN', 'Author', 'Title'],
            sourceOptions: [
                { value: 'All Sources', text: 'All Sources' },
                { value: 'Store Managed', text: 'Store Managed' },
                { value: 'None', text: 'None' }
            ],
            rentalOptions: [
                { value: 'Store Managed', text: 'Store Managed' },
                { value: 'None', text: 'None' }
            ],
            refreshSearchResults: false,
            fields: [
                {
                    label: '',
                    key: 'edit'
                },
                {
                    key: 'author',
                    sortable: true,
                    label: 'Author/Title/ISBN',
                },
                {
                    key: 'author',
                    sortable: true,
                    label: 'Author',
                    automaticSearch: true,
                    hidden : true
                },
                {
                    key: 'title',
                    sortable: true,
                    label: 'Title',
                    automaticSearch: true,
                    hidden : true
                },
                {
                    key: 'isbn',
                    sortable: true,
                    label: 'ISBN',
                    automaticSearch: true,
                    hidden : true
                },
                {
                    key: 'rentalSource',
                    sortable: true,
                    label: 'Rental Source',
                    tdClass: 'noWrap'
                },
                {
                    label: '',
                    key: 'newUsed',
                },
                {
                    key: 'newRentalPrice',
                    sortable: true,
                    label: 'Rental Price',
                    tdClass: 'text-right',
                    thClass: 'text-center'
                },
                {
                    key: 'newPrice',
                    sortable: true,
                    label: 'Price',
                    tdClass: 'text-right',
                    thClass: 'text-center'
                },
                {
                    key: 'newCost',
                    sortable: true,
                    label: 'Cost',
                    tdClass: 'text-right',
                    thClass: 'text-center'
                },
                {
                    key: 'defaultLateFee',
                    sortable: true,
                    label: 'Late Fee',
                    tdClass: 'text-right',
                    thClass: 'text-center'
                },
                {
                    key: 'rebatePrice',
                    sortable: true,
                    label: 'Rebate',
                    tdClass: 'text-right',
                    thClass: 'text-center'
                },
                {
                    key: 'newIsActive',
                    sortable: true,
                    label: 'Status',
                }
            ],
            rentalData: {},
            rentalTableData: [],
            FilteredrentalTableData: [],
            termName: '',
            terms: null,
            exportParameters: {
                exportFormat: '',
                data: []
            }
        };
    },
    components: {
        rental
    },
    watch: {
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    methods: {
        dateFormatter: dateFormatter,
        currencyFormatter: currencyFormatter,
        loadData() {
            this.termChange(this.filters.termId);
            let filteredTerms = this.terms.filter(x=>x.termId>0);
            if(filteredTerms.length == 1){
                this.filters.termId=filteredTerms[0].termId
            }
        },
        termChange(value) {
            this.selectedTermId = value;
            let promise = axios.get('rentals/gettermrental', {
                params: { termId: value }
            });
            let promise1 = axios.get('rentals/GetAllTermRentals', {
                params: { termId: value }
            });

            Promise.all([promise, promise1]).
            then(res => {
                this.rentalData = res[0].data;
                this.rentalTableData = res[1].data.data;
            });

            this.termName = this.$store.getters.getTermById(value)?.text;
            this.isOpenTerm =
                this.$store.getters.getTermById(value)?.filter == 'True';
            this.isActiveTerm = this.$store.getters.getTermById(
                value
            )?.isActiveTerm;
        },
        filterTerm(term) {
            if (term.termId == this.selectedTermId) {
                this.isOpenTerm = term.filter == 'True';

                this.isActiveTerm = term.isActiveTerm;
            }
            return term.termId > 0;
        },
        updateRentalSearch() {
            // this.$refs?.rentalSearch.refreshSearchResults();
            this.loadData();
        },
        updatedTableData(e) {
            this.FilteredrentalTableData =  e;
        },
        async downloadFile(exportFormat) {
            let apiUrl = '/rentals/exportrentallist';
            this.exportParameters.exportFormat = exportFormat;
            this.exportParameters.data = this.FilteredrentalTableData;
            this.isBusy = true;

            axios
            .post(apiUrl, this.exportParameters, { responseType: 'blob' })
                .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        exportFormat == 'pdf'
                            ? `Rental Management`
                            : `Rental Management.csv`,
                        exportFormat == 'pdf'
                            ? 'application/pdf'
                            : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Rental Management report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        }
    },
    mounted: async function() {
        this.terms = await selectListOptionsDataContext.getStoreDropdownData(
            'terms'
        );
        this.loadData();
    },
    computed: {
           filteredTableData(){
            let rentalSource = this.filters.rentalSource;
                if(rentalSource != 'All Sources'){
                        return JSON.parse(JSON.stringify(this.rentalTableData.filter(function (el) {
                            return el.rentalSource == rentalSource;
                        
                            })));
                }else{
                    return this.rentalTableData;
                }
           }
    },
};
</script>
