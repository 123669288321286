<template>
    <p-feature-flag ref="AccountingFlag" name="Accounting">
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="stockAdjustmentReason">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Stock Adjustment Reason</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button type="submit" variant="primary" :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-md="3">
                    <b-col>
                        <p-input
                            label="Adjustment Reason"
                            v-model="stockAdjustmentReason.reason"
                            rules="required|max:100"
                        />
                    </b-col>
                    <b-col>
                        <p-input
                            label="Reason Description"
                            v-model="stockAdjustmentReason.description"
                            rules="max:500"
                        />
                    </b-col> 
                    <b-col>
                        <p-checkbox
                            label="Active"
                            :use-switch="true"
                            v-model="stockAdjustmentReason.isActive"
                            class="mt-3 pt-3 mb-3 pb-3"
                        />
                    </b-col>                                                
                </b-row>
            </p-card>
            <p-card>
                <b-row>
                    <b-col>
                        <GLCodeLocation
                            :items="stockAdjustmentReason.locations"
                            :fields="fields"
                            :filterchartofaccounts="filterchartofaccounts" />
                    </b-col>   
                </b-row>
            </p-card>
            <b-row class="mt-3">
                <b-col class="text-left">
                    <p-button
                        variant="outline-danger"
                        type="button"
                        :is-busy="isBusy"
                        @click="deleteReason"
                        v-if="!isNew && !stockAdjustmentReason.isActive"
                        >Delete</p-button
                    >
                </b-col>
                <b-col style="float:left;">
                    <p-button
                        variant="primary"
                        type="submit"
                        :is-busy="isBusy"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
    </p-feature-flag>
</template>

<script>
    import axios from "axios";
    import NavigationGuard from '@/components/mixins/NavigationGuard.js'
    import editPage from '@/components/mixins/EditPage';
    import GLCodeLocation from '@/components/GLCodeLocation';

    export default {
        mixins: [NavigationGuard, editPage],
        components: {
            GLCodeLocation
        },
        data() {
            return {
                stockAdjustmentReason: null,
                isBusy: false,
                fields:[
                {
                    key: 'locationName',
                    label: 'Location',
                    tdClass: 'reasonLocation'
                },
                {
                    key: 'glCode',
                    label: 'GL Code',
                }
            ]
            };
        },
        watch: {
            stockAdjustmentReason: NavigationGuard.$watcher
        },
        methods: {
            onSubmit() {
                this.isBusy = true;
                axios.post("stockadjustmentreason", this.stockAdjustmentReason)
                    .then((response) => {
                        this.dataSaved();
                        if (this.isNew) {
                            this.$router.push("/stockadjustmentreason/" + response.data);
                        }
                        this.$toasted.global.app_success(`Stock Adjustment Reason saved successfully.`).goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
        },
        filterchartofaccounts(chartofaccount) {
            return chartofaccount.type == 'Stock Adjustment' || chartofaccount.type == null;        
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('stockadjustmentreason/new')
                : axios.get('stockadjustmentreason', { params: { id: this.id } });

            promise.then(resp => {
                this.stockAdjustmentReason = resp.data;
                this.dataLoaded();                
            });
        },
        deleteReason() {            
                this.isBusy = true;
                axios
                    .post('stockadjustmentreason/delete', this.stockAdjustmentReason)
                    .then(() => {
                        this.dataSaved();
                        this.$router.push('/stockadjustmentreasons');
                        this.$toasted.global
                            .app_success(`Stock Adjustment Reason deleted successfully.`)
                            .goAway(5000);
                    })
                    .finally(() => {
                        this.isBusy = false;
                    });
            }
        },
        mounted: function() {
            this.loadData();
        }
    };
</script>

<style scoped lang="scss">
/deep/ .reasonLocation {
    width:500px;
}
</style>

