<template>
    <p-feature-flag
        name="POS Admin"
        redirect>
        <p-form @submit="onSubmit">
            <div class="container-fluid content-wrapper" v-if="nonMerch">
                <b-row class="content-heading">
                    <b-col>
                        <div>{{ isNew ? 'Add' : 'Edit' }} Non Merch</div>
                    </b-col>
                    <b-col class="text-right">
                        <p-button type="submit" :is-busy="isBusy" variant="primary">Save</p-button>
                    </b-col>
                </b-row>
                <p-card>
                    <b-row>
                        <b-col lg="3">
                            <p-input
                                label="Name"
                                v-model="nonMerch.name"
                                rules="required|max:50" />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="3">
                            <p-select
                                data-type="locations"
                                v-model="nonMerch.locationId"
                                label="Location"
                                rules="required" />
                        </b-col>
                    </b-row>    
                    
                    <b-row>
                        <b-col lg="3">
                            <p-select
                                data-type="taxItems"
                                v-model="nonMerch.taxItemId"
                                label="Tax Type"
                                rules="required" />
                        </b-col>
                    </b-row>                         

                    <b-row>
                        <b-col lg="2">
                            <p-number
                                label="Price"
                                v-model="nonMerch.price"
                                input-type="money"
                                left-align />
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col lg="2">
                            <p-checkbox
                                label="Is Discount Allowed"
                                v-model="nonMerch.allowDiscounts" />
                        </b-col>
                    </b-row>    
                    
                    <b-row>
                        <b-col lg="2">
                            <p-checkbox
                                label="Is Refundable"
                                v-model="nonMerch.isRefundable" />
                        </b-col>
                    </b-row>     

                    <b-row>
                        <b-col lg="2">
                            <p-checkbox
                                label="Active"
                                :use-switch="true"
                                class="mt-3 pt-3 mb-3 pb-3"
                                v-model="nonMerch.isActive"
                            />
                        </b-col>
                    </b-row>
                </p-card>
                <b-row class="mt-4">
                    <b-col class="text-center mb-3">
                        <p-button
                            variant="primary"
                            type="submit"
                            :is-busy="isBusy"
                            class="text-right"
                            >Save</p-button
                        >
                    </b-col>
                </b-row>
            </div>
        </p-form>
    </p-feature-flag>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js'
import editPage from '@/components/mixins/EditPage';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            nonMerch: null,
            isBusy: false
        };
    },
    watch: {
        nonMerch: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    methods: {
        onSubmit() {
            this.isBusy = true;
            axios.post("nonmerch", this.nonMerch)
                .then((response) => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push("/nonmerch/" + response.data);
                    }
                    this.$toasted.global.app_success(`Non Merch '${this.nonMerch.name}' saved successfully.`).goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        async loadData() {
            let promise = this.isNew
                ? axios.get('nonmerch/new')
                : axios.get('nonmerch', { params: { id: this.id } });

            promise.then(async resp => {
                this.nonMerch = resp.data;

                if (this.isNew) {
                    this.nonMerch.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                }
                else {
                    this.dataLoaded();
                }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>