<template>
    <SearchScreen
      title="Pay-In / Pay-Out"
      api-destination="payinpayouts"
      edit-key="specialItemId"
      :filters="filters"
      :fields="fields"
      :filterBy="filterBy"
      add-new-button
    >
    </SearchScreen>
  </template>
  
  <script>
  import SearchScreen from "../../components/SearchScreen.vue";
  import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
  import {booleanToYesNoFormatter} from "@/components/Common/Formatters.js";
  
  export default {
    mixins: [SearchStorage],
    data() {
      return {
        filterName: 'payinpayoutsFilter',
        filters: {
          automatic: "",
          perPage: 100,
          sortBy: 'name',
          sortDesc: true,
          searchBy:'All'
        },
        filterBy:[
            'All',
            'Name',
            'Type'
        ],
        fields: [
          {
            key: "name",
            sortable: true
          },
          {
            key: "type"
          },
          {
            key: "requiresMerchantReceipt",
            formatter: booleanToYesNoFormatter
          },
          {
            key: "requiresAccount",
            formatter: booleanToYesNoFormatter
          },
          {
            key: "isActive",
            disabled: true
          }
        ]
      };
    },
    components: {
      SearchScreen
    }
  };
  </script>
  
  