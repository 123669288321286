<template>
    <p-form @submit='onSubmit' ref="form">
        <div class="container-fluid content-wrapper" v-if="tender">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Tender</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <p-button variant="primary" type="submit" :is-busy="isBusy">Save</p-button>
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-lg="2">
                    <b-col>
                        <p-input
                            label="Name"
                            v-model="tender.tenderName"
                            rules="required|max:80"
                        />                        
                        <p-number
                            label="Minimum Length"
                            v-model="tender.minLength"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number
                            label="Maximum Length"
                            v-model="tender.maxLength"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number
                            label="POS Tender Code"
                            v-model="tender.posTenderCode"
                            rules="max:12"
                            left-align
                        />
                    </b-col>
                    <b-col style="padding-top: 25px;">
                        <p-checkbox
                            label="Is Cash Tender"
                            v-model="tender.isCash"
                        />
                        <p-checkbox
                            label="Is Check Tender"
                            v-model="tender.isCheck"
                        />
                        <p-checkbox
                            label="Allow As Guarantee Tender"
                            v-model="tender.allowAsGuarantee"
                            class="pb-4"
                        />
                    </b-col>
                </b-row>                
                <p-card title="POS Tenders">
                    <b-row cols="6">
                        <b-col>
                            <p-button
                                variant="outline-primary"
                                class="mt-2"
                                @click="addPosTender()"
                                v-b-modal.posTenderModal
                                :disabled="disableAddPosTender"
                                style="padding-top: 15px">
                                    Add POS Tender</p-button>
                        </b-col>
                        <b-col col="2">
                            <p-advanced-select
                                label='GL Code - Batch update for all Locations <br /> ( This field does not stay populated )'
                                data-type="chartOfAccounts"
                                :filter="glcodeFilter"
                                style="padding-left: -10px"
                                @input="setAllPOSCOAID($event)"
                                v-model="batchGLCodeSetValue"
                                add-empty-option
                                empty-value-space
                            />
                        </b-col>
                    </b-row>
                    <p-table
                        :items="tender.posTenders"
                        :fields="fields"
                        :enable-row-selection="false"
                        :per-page="50">
                        <template v-slot:cell(view)="{ value, item }">
                            <b-button variant="outline-primary" size="sm" @click='setPosTender(item, true)' v-b-modal.posTenderModal>Edit</b-button>
                        </template>
                        <template v-slot:cell(chartOfAccountsId)="{ value, item }">
                            <div>{{ getGLCode(value) }}</div>
                        </template>
                    </p-table>
                </p-card>
            </p-card>
            <b-row>
                <b-col class='mt-3 text-center'>
                    <p-button variant='primary' type='submit' :isBusy='isBusy'>Save</p-button>
                </b-col>
            </b-row>
        </div>
        <p-modal v-if="selectedPosTender"
                 size="lg"
                 name="posTenderModal"
                 label="POS Tender"
                 ref="posTenderModal"
                 @hide="onModalHide($event)"
                 @cancel="onModalCancel"
                 @ok="addPosTenderOk($event)"
                 ok-label="Save & Close">
                 <b-row>
                    <b-col>
                        <p-select
                            label="POS Name"
                            data-type="pos"
                            v-model="selectedPosTender.posId"
                            :filter="posFilter"
                            :disabled="disablePosSelection"
                            @change="preventInitialValidationError"
                            rules="required"
                        />
                        <p-select
                            label="Auth Type"
                            data-type="tenderAuthTypes"
                            v-model="selectedPosTender.tenderAuthTypeId"
                        />
                        <p-select
                            label="MSR Card"
                            data-type="msrCards"
                            add-empty-option
                            v-model="selectedPosTender.msrCardId"
                        />
                        <p-advanced-select
                            label="GL Code"
                            data-type="chartOfAccounts"
                            :filter="glcodeFilter"
                            v-model="selectedPosTender.chartOfAccountsId"
                        />
                        <p-number 
                            label="Rounding Unit (coin)"
                            v-model="selectedPosTender.roundingUnit"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number 
                            label="Rounding Fee Item ID"
                            v-model="selectedPosTender.roundingFeeItemId"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number 
                            label="Max Change Due"
                            v-model="selectedPosTender.maxChangeDue"
                            input-type='decimal'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number 
                            label="Auth Timeout"
                            v-model="selectedPosTender.authorizationTimeout"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                        <p-number 
                            label="Sort Order"
                            v-model="selectedPosTender.displayOrder"
                            input-type='integer'
                            rules="min_value:0"
                            left-align
                        />
                    </b-col>
                    <b-col style="padding-left: 35px;">
                        <p-checkbox
                            label="Enable for Web"
                            v-model="selectedPosTender.isActiveOnline"
                        />
                        <p-checkbox
                            label="Use Cash Rounding"
                            v-model="selectedPosTender.cashRounding"
                        />
                        <p-checkbox
                            label="Is Taxed"
                            v-model="selectedPosTender.isTaxed"
                        />
                        <p-checkbox
                            label="Is Disabled"
                            v-model="selectedPosTender.isDisabled"
                        />
                        <p-checkbox
                            label="Requires Account"
                            v-model="selectedPosTender.requiresAccount"
                        />
                        <p-checkbox
                            label="Requires Date"
                            v-model="selectedPosTender.requiresDate"
                        />
                        <p-checkbox
                            label="Requires CID"
                            v-model="selectedPosTender.requiresCid"
                        />
                        <p-checkbox
                            label="Requires AVS"
                            v-model="selectedPosTender.requiresAvs"
                        />
                        <p-checkbox
                            label="Capture Signature on PIN Pad"
                            v-model="selectedPosTender.requiresSignature"
                        />
                        <p-checkbox
                            label="Print Signature Line"
                            v-model="selectedPosTender.printSignatureLine"
                        />
                        <p-checkbox
                            label="Requires Amount Entry"
                            v-model="selectedPosTender.requiresAmountEntry"
                        />
                        <p-checkbox
                            label="Opens Cash Drawer"
                            v-model="selectedPosTender.opensCashDrawer"
                        />
                        <p-checkbox
                            label="Include In Cash Count"
                            v-model="selectedPosTender.includeInCashCount"
                        />
                        <p-checkbox
                            label="Allow Change Due"
                            v-model="selectedPosTender.allowChangeDue"
                        />
                        <p-checkbox
                            label="Requires Customer Receipt"
                            v-model="selectedPosTender.requiresCustomerReceipt"
                        />
                        <p-checkbox
                            label="Requires Merchant Receipt"
                            v-model="selectedPosTender.requiresMerchantReceipt"
                        />
                    </b-col>
                </b-row>
        </p-modal>                 
    </p-form>    
</template>

<script>
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import editPage from '@/components/mixins/EditPage';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import axios from 'axios';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            isBusy: false,
            tender: {
                posTenders: []
            },
            selectedPosTender: null,
            posList: null,
            tenderAuthTypes: null,
            glCodes: null,
            filterAllPos: false,
            disablePosSelection: true,
            originalValues: null,
            batchGLCodeSetValue: -1,
            fields: [
                {
                    key: 'view',
                    label: '',
                    class: 'tcw100'
                },
                {
                    key: 'posName',
                    label: 'POS'
                },
                {
                    key: 'isActiveOnline',
                    label: 'Enabled For Web'
                },
                {
                    key: 'chartOfAccountsId',
                    label: 'GL Code'
                },
                {
                    key: 'tenderAuthType',
                    label: 'Auth Type'
                },
                {
                    key: 'isTaxed',
                    label: 'Taxed'
                },
                {
                    key: 'isDisabled',
                    label: 'Disabled'
                },
                {
                    key: 'displayOrder',
                    label: 'Sort Order'
                }
            ]
        };
    },
    mounted: async function() {
        this.posList = await selectListOptionsDataContext.getStoreDropdownData('pos');
        this.tenderAuthTypes = await selectListOptionsDataContext.getStoreDropdownData('tenderAuthTypes');
        this.glCodes = await selectListOptionsDataContext.getStoreDropdownData('chartOfAccounts');
        this.loadData();
    },
    computed: {
        disableAddPosTender()
        {
            if(!this.tender || !this.tender.posTenders || !this.posList)
            {
                return false;
            }
            return this.posList.every(tender => this.tender.posTenders.some(tender2 => tender.value === tender2.posId));
        }
    },
    methods: {
        async preventInitialValidationError()
        {
            this.$nextTick(async () => {
                await this.$refs.form.validate();
            });
        },
        glcodeFilter(chartofaccount)
        {
            return  (chartofaccount.type == "Tender" || chartofaccount.type == null);
        },
        setAllPOSCOAID(value)
        {
            this.tender.posTenders.forEach(x => x.chartOfAccountsId = value);
            this.$nextTick(async () => {
                this.batchGLCodeSetValue = -1;
            });
        },
        getGLCode(value)
        {
            if(!this.glCodes)
            {
                return;
            }
            return this.glCodes.find(x => x.value == value).text.split(" ", 2)[0];
        },
        onModalHide(event)
        {
            if(event.trigger === 'backdrop')
            {
                event.preventDefault();   
            }

            if(event.trigger === 'cancel' || event.trigger === 'headerclose')
            {
                this.onModalCancel();
                return;
            }            
        },
        onModalCancel()
        {
            var index = this.tender.posTenders.findIndex(x => x.posId == this.originalValues.posId);
            if(index > -1)
            {
                Object.assign(this.tender.posTenders[index], this.originalValues);
            }else{
                this.addPosTender();
            }           

            this.$refs.posTenderModal.hide();
        },
        posFilter(pos)
        {
            if(!this.tender || !this.tender.posTenders)
            {
                return true;
            }
            return this.filterAllPos || !this.tender.posTenders.some(tender => tender.posId === pos.value);
        },
        async addPosTenderOk(event)
        {
            if(!this.selectedPosTender.posId)
            {
                event.preventDefault();
                await this.$refs.form.validate();
               
                return;
            }
            this.filterAllPos = true;   
            this.selectedPosTender.tenderAuthType = this.tenderAuthTypes.find(authType => this.selectedPosTender.tenderAuthTypeId === authType.value)?.text
            if(!this.tender.posTenders.some(tender => tender.posName == this.selectedPosTender.posName))
            {
                this.tender.posTenders.push(this.selectedPosTender);
                this.selectedPosTender.posName = this.posList.find(pos => pos.value === this.selectedPosTender.posId)?.text;
            }           
            
            this.onSubmit();
        },
        addPosTender()
        {            
            this.setPosTender({
                "posTenderId": null,
                "posId": null,
                "tenderId": this.tender.tenderId,
                "tenderAuthType": "No Authorization",
                "tenderAuthTypeId": 1,
                "isTaxed": true,
                "isDisabled": false,
                "requiresDate": false,
                "requiresCid": false,
                "requiresAvs": false,
                "requiresSignature": false,
                "requiresAmountEntry": false,
                "opensCashDrawer": false,
                "includeInCashCount": false,
                "allowChangeDue": false,
                "maxChangeDue": 0.0000,
                "authorizationTimeout": 60,
                "displayOrder": 0,
                "requiresMerchantReceipt": false,
                "insertId": null,
                "endorseId": null,
                "printSignatureLine": false,
                "requiresCustomerReceipt": false,
                "requiresAccount": false,
                "cashRounding": false,
                "roundingUnit": null,
                "roundingFeeItemId": null,
                "isActiveOnline": false,
                "msrCardId": null,
                "chartOfAccountsId": 0
            }, false);
        },
        setPosTender(item, editItem)
        {
            this.disablePosSelection = editItem;
            this.filterAllPos = editItem;
            this.originalValues = JSON.parse(JSON.stringify(item));
            this.selectedPosTender = item;            
        },
        async loadData() {
            let promise = this.isNew
                ? axios.get('tenders/new')
                : axios.get('tenders', { params: { id: this.id } });
                        
            promise.then(resp => {
                this.tender = resp.data;
                this.dataLoaded();
            });
        },
        onSubmit() {
            this.isBusy = true;
            axios.post('tenders', this.tender).then(resp => {
                this.dataSaved();
                if (this.isNew) {                    
                    this.$router.push("/tenders/" + resp.data);
                }

                this.$toasted.global
                    .app_success(
                        `Tender saved successfully.`
                    )
                    .goAway(5000);
            }).finally(() => {
                this.isBusy = false;
            });
        }
    },
    watch: {
        tender: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    }
};
</script>

<style scoped>
.tcw100 {
    width: 100px;
}
</style>