let Menu = [
    {
        name: 'Dashboard',
        featureFlag: 'Dashboard',
        path: '/dashboard',
        icon: 'fas fa-tachometer-alt'
    },
    {
        name: 'Reports',
        icon: 'fas fa-chart-bar',
        submenu: [
            {
                name: 'Report Templates',
                featureFlag: 'Report Templates',
                path: '/reports'
            },
            {
                name: 'Archived Reports',
                featureFlag: 'Archived Reports',
                path: '/archivedreports'
            },
            {
                name: 'Transaction Search',
                featureFlag: 'Transactions',
                path: '/reports/transactions'
            }
        ]
    },
    {
        name: 'Products',
        icon: 'fas fa-tag',
        submenu: [
            {
                name: 'Product Maintenance',
                path: '/products'
            },
            {
                name: 'Product Variant Groups',
                featureFlag: 'Product Variant Groups',
                path: '/productvariantgroups'
            },
            {
                name: 'Product Variants',
                featureFlag: 'Product Variants',
                path: '/productvariants'
            },
            {
                name: 'Physical Inventory',
                path: '/physicalinventory'
            },
            {
                name: 'Stock Adjustment Report',
                path: '/stockadjustmentreport'
            },
            {
                name: 'Product Transfers',
                featureFlag: 'Product Transfer',
                path: '/producttransfers',
                requireMultiLocation: true
            }
        ]
    },
    {
        name: 'Course Materials',
        icon: 'fas fa-book',
        singleLocationFilter: true,
        submenu: [
            {
                name: 'Campuses',
                path: '/campuses',
                singleLocationFilter: true,
            },
            {
                name: 'Departments',
                path: '/academicdepartments',
                singleLocationFilter: true,
            },
            {
                name: 'Instructors',
                path: '/instructors',
                singleLocationFilter: true,
            },
            {
                name: 'Courses',
                path: '/courses',
                icon: 'fas fa-hammer',
                singleLocationFilter: true,
            },
            {
                name: 'Terms',
                path: '/terms',
                singleLocationFilter: true,
            },
            {
                name: 'Course Requests',
                path: '/courserequests',
                icon: 'fas fa-clipboard-list',
                singleLocationFilter: true,
            },
            {
                name: 'Order Decisions',
                path: '/orderdecisions',
                singleLocationFilter: true,
            },
            {
                name: 'Rental Management',
                featureFlag: 'Rental Management',
                path: '/rentalmanagement',
                singleLocationFilter: true,
            },
            {
                name: 'Buyers Guide Search',
                path: '/buyersguidesearch',
                singleLocationFilter: true,
            }
        ]
    },
    {
        name: 'Purchasing',
        icon: 'fas fa-receipt',
        submenu: [
            {
                name: 'Purchase Orders',
                path: '/purchaseorders'
            },
            {
                name: 'Invoices',
                path: '/invoices',
                icon: 'fas fa-file-invoice'
            },
            {
                name: 'Returns',
                path: '/creditrequests'
            },
            {
                name: 'Want Lists',
                path: '/wantlists'
            },
            {
                name: 'Sale To Wholesale',
                path: '/wholesale'
            },
            {
                name: 'Auto Gen',
                path: '/purchaseorderautogen'
            }
        ]
    },
    {
        name: 'Buyback',
        icon: 'fas fa-shopping-basket',
        featureFlag: 'Buyback',
        requireRole: ['Store Manager', 'Buyback User'],
        submenu: [
            {
                name: 'Buyers',
                path: '/buybackbuyers',
                requireRole: ['Store Manager']
            },
            {
                name: 'Purchasers',
                path: '/buybackpurchasers',
                requireRole: ['Store Manager']
            },
            {
                name: 'Sessions',
                path: '/buybacksessions',
                requireRole: ['Store Manager']
            },
            {
                name: 'Buyback List',
                path: '/buybacklists',
                requireRole: ['Store Manager']
            },
            {
                name: 'Buy Books',
                path: '/buybooks',
                requireRole: ['Buyback User']
            }
        ]
    },
    {
        name: 'Suppliers',
        path: '/suppliers',
        icon: 'fas fa-truck'
    },
    {
        name: 'People & Accounts',
        icon: 'fas fa-user',
        requireRole: ['Store Manager'],
        submenu: [
            {
                name: 'Gift Cards',
                featureFlag: 'Gift Cards',
                path: '/giftcards',
            },
            {
                name: 'AR Account Management',
                featureFlag: 'AR account management',
                path: '/accountreceivable',
            },
            {
                name: 'Customers',
                featureFlag: 'Customers',
                path: '/customers',
            }
        ]
    },
    {
        name: 'Web Admin',
        featureFlag: 'Web orders',
        singleLocationFilter: true,
        icon: 'fas fa-shopping-cart',
        requireRole: ['Admin', 'Merchandise Manager', 'Store Manager'],
        submenu: [
            {
                name: 'Web Orders',
                path: '/weborders',
                singleLocationFilter: true,
            },
            {
                name: 'Shipping Codes',
                path: '/shippingcodes',
                singleLocationFilter: true,
            },
            {
                name: 'Web Catalogs',
                path: '/webcatalogs',
                singleLocationFilter: true,
            },
            {
                name: 'Web Catalog Groups',
                path: '/webcataloggroups',
                singleLocationFilter: true,
            },
            {
                name: 'Web Setup',
                path: '/websetup',
                singleLocationFilter: true,
            }
        ]
    },
    {
        name: 'Accounting',
        icon: 'fas fa-calculator',
        featureFlag: 'Accounting',
        requireRole: ['Admin', 'Store Manager'],
        submenu: [            
            {
                name: 'Stock Adjust Reasons',
                path: '/stockAdjustmentReasons'
            },
            {
                name: 'Invoice Adjust Reasons',
                path: '/invoiceAdjustmentReasons'
            },
            {
                name: 'Chart of Accounts',
                path: '/chartOfAccounts'
            },
            {
                name: 'Stock Ledger',
                path: '/stockledgers'
            }
        ]
    },
    {
        name: 'POS Admin',
        icon: 'fas fa-cash-register',
        requireRole: ['Admin', 'Merchandise Manager', 'Store Manager'],
        submenu: [
            {
                name: "Tenders",
                path: '/tenders'
            },
            {
                name: 'Sales Events',
                path: '/salesevents',
                featureFlag: 'Sales Events',
            },
            {
                name: 'Pay-In / Pay-Out',
                path: '/payinpayouts'
            },
            {
                name: 'Tax Rates',
                path: '/taxrates',
                featureFlag: 'Taxes',
                requireRole: ['Store Manager']
            },
            {
                name: 'Manage POS',
                featureFlag: 'Manage POS',
                hasLink: true,
            }
        ]
    },
    {
        name: 'Administrator',
        shortName: 'Site Admin',
        icon: 'fas fa-user-cog',
        submenu: [
            {
                name: 'Users',
                path: '/users',
                requireAdmin: true
            },
            {
                name: 'Store Setup',
                path: '/storesetup'
            },
            {
                name: 'Sequences',
                path: '/sequences',
                requireRole: ['Store Manager']
            },
            {
                name: 'DCCs',
                path: '/dccs'
            },
            {
                name: 'Buyers Guide',
                path: '/buyersGuide'
            },
            {
                name: 'Imports',
                path: '/imports'
            },
            {
                name: 'Data Exchange',
                path: '/dataexchange',
                featureFlag: 'Data Exchange',
            }
        ]
    },
    {
        name: 'POS Admin',
        shortName: 'POS Admin',
        icon: 'fas fa-cash-register',
        featureFlag: 'POS Admin',
        requireRole: ['Admin', 'Merchandise Manager', 'Store Manager'],
        submenu: [
            {
                name: "Tenders",
                path: '/tenders'
            },
            {
                name: 'Sales Events',
                path: '/salesevents',
                featureFlag: 'Sales Events',
            },
            {
                name: 'Tax Rates',
                path: '/taxrates',
                featureFlag: 'Taxes',
                requireRole: ['Store Manager']
            },

            {
                name: 'Pay-In / Pay-Out',
                path: '/payinpayouts'
            },
            {
                name: 'Non Merch',
                path: '/nonmerch'
            },
            {
                name: 'Manage POS',
                featureFlag: 'Manage POS',
                hasLink: true,
            },                     
        ]
    },    
];
if (process.env.VUE_APP_SHOW_DEMO == 'true') {
    Menu.push({
        name: 'Examples',
        icon: 'fas fa-hippo',
        submenu: [
            {
                name: 'Demos',
                path: '/demos'
            },
            {
                name: 'Page Layout',
                path: '/Pagelayout'
            }
        ]
    });
}

Menu.push(
    {
        name: 'Mosaic',
        path: '/mosaic',
        icon: 'fas fa-receipt',
        prismRBSOnly: true,
        submenu: [
            {
                name: 'Feature Flags',
                path: '/featureFlags',
                requireRole: ['Developer']
            },
            {
                name: 'Tenants',
                path: '/tenants',
                requireRole: ['Developer', 'Support']
            }
        ]
    },
)

export default Menu;
