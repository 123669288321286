<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="specialItem">
            <b-row class="content-heading">
                <b-col>
                    <div>{{isNew ? 'Add' : 'Edit'}} - {{ specialItem.type ? specialItem.type : 'New Payin/Payout' }}</div>
                </b-col>
                <b-col cols="auto" class="ml-auto">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
            <p-card>
            <b-row cols="1" cols-sm="2" cols-xl="3">
                    <b-col>
                        <p-input
                            label="Name"
                            v-model="specialItem.name"
                            rules="required|max:50"
                            @change="doesNameExist"
                        />
                    </b-col>
                    <b-col>
                        <p-select 
                            label="Type"
                            :options="payipayoutTypes"
                            v-model="specialItem.type"
                            rules="required"
                            :disabled="!isNew"
                            />
                    </b-col>
                    <b-col>
                        <p-checkbox
                            label="Is Active"
                            v-model="specialItem.isActive"
                        />
                        <p-checkbox
                            label="Requires Merchant Receipt"
                            v-model="specialItem.requiresMerchantReceipt"
                        />
                        <p-checkbox
                            label="Requires Account"
                            v-model="specialItem.requiresAccount"
                        />
                    </b-col>
                    <b-col>
                        <p-select 
                            label="GL Code"
                            data-type="ChartOfAccounts"
                            :filter="filterchartofaccounts"
                            v-model="specialItem.chartOfAccountsId"
                            />
                    </b-col>
                </b-row>
            </p-card>              
            <b-row>
                <b-col class=" mb-3 text-center">
                    <b-button type="submit" variant="primary">Save</b-button>
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js';
import { PayInPayOutEnum } from '@/components/Enums/Enums.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

    export default {
        mixins: [NavigationGuard],
        props: {
            id: String
        },
        data() {
            return {
                nameExists: false,
                specialItem: {},
                payipayoutTypes: [
                    PayInPayOutEnum.PAYIN,
                    PayInPayOutEnum.PAYOUT
                ]
            };
        },
        computed: {
            isNew() {
                return this.id === 'new';
            }
        },
        watch: {
            specialItem: NavigationGuard.$watcher,
            $route: function (to, from) {
                if (to !== from) {
                    this.loadData();
                }
            }
        },
        methods: {
            doesNameExist(name)
            {
                axios.get("payinpayouts/doesnameexist", { params: { name: name, id: this.specialItem.specialItemId } }).then(response => {
                    this.nameExists = response.data;
                    if(this.nameExists)
                    {
                        this.displayNameError();
                    }
                });
            },
            filterchartofaccounts(chartofaccount) {
                return (this.isNew && !this.specialItem.type
                        && (chartofaccount.type == PayInPayOutEnum.PAYIN || chartofaccount.type == PayInPayOutEnum.PAYOUT || chartofaccount.type == null)) 
                || (this.specialItem.type == PayInPayOutEnum.PAYIN && chartofaccount.type == PayInPayOutEnum.PAYIN || chartofaccount.value == 0 /* value of 0 is unassigned */)
                || (this.specialItem.type == PayInPayOutEnum.PAYOUT && chartofaccount.type == PayInPayOutEnum.PAYOUT || chartofaccount.value == 0  /* value of 0 is unassigned */);
            },
            displayNameError(){
                this.$toasted.global.app_error(`A Pay-In or Pay-Out exists with this name - ${this.specialItem.name}. Please change the name.`);
            },
            onSubmit() {
                if(this.nameExists)
                {
                    this.displayNameError();
                    return;
                }

                axios.post("payinpayouts", this.specialItem).then(response => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push("/payinpayouts/" + response.data);
                    }
                    this.$toasted.global.app_success(`${this.specialItem.type} - ${this.specialItem.name} saved successfully.`).goAway(5000);
                });
            },
            async loadData() {
                let promise = this.isNew
                    ? axios.get('payinpayouts/new')
                    : axios.get('payinpayouts', { params: { id: this.id } });
                let locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
                promise.then(resp => {
                    this.specialItem = resp.data;
                    this.specialItem.locationId = locationId;
                    this.dataLoaded();
                });
            }
        },
        mounted: function () {
            this.loadData();
        }
    };
</script>

<style  scoped lang="scss">
/deep/ .reasonLocation {
    width:500px;
}
</style>
  