<template>
    <p-table
        :items="items"
        :fields="fields"
        :enableRowSelection="false"
        style="margin-left: 40px; margin-right: 40px;"
        :per-page="25"
        class="wide"
        :show-record-count="false">
        
        <template v-slot:cell(glCode)="{ value, item }">
            <p-advanced-select
              data-type="ChartOfAccounts"
              v-model="item.chartOfAccountsId"
              :filter="filterchartofaccounts"
              style="display: block; flex-grow: 1; max-height: 34px; padding-right: 10px"
              />
        </template>
    </p-table>
</template>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },
      fields: {
        type: Array,
        required: true
      },
      filterchartofaccounts: {
        type: Function,
        required: true
      }
    }
  };
</script>