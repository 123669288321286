<template>                    
    <SearchScreen
        title="Stock Ledger"
        api-destination="stockledgers"
        ref="searchScreen"
        :filters="filters"
        :fields="fields"
        :filter-by="filterBy"
        @total-rows-changed="getTotalRows"
    >
    <template v-slot:actionButtons>
        <b-row cols="4">                       
            <b-col>
                <b-button
                    variant="outline-primary"
                    class="mr-2"
                    v-on:click="printScreen()"
                    :disabled="totalRows <= 0 "
                    >Print</b-button
                >
            </b-col>
            <b-col>
                <b-button variant="outline-primary" style="width: 110px; margin-left: 10px;" @click="downloadFile('csv')"
                    :disabled="totalRows <= 0 " >Export (CSV)</b-button
                >
            </b-col>
        </b-row>
    </template>
        <template v-slot:belowSearch>
            <b-row  class="ml-1">
                <b-col cols="3">
                        <p-datepicker label="Start Date"
                            v-model="filters.startDate"
                            vid="reportStartDate" />
                        </b-col>
                    <b-col cols="3">
                        <p-datepicker label="End Date"
                            vid="endDate"
                            is-end-date
                            rules="dateGreaterOrEqual:@reportStartDate"
                            v-model="filters.endDate" />
                    </b-col>
            </b-row>
        </template>
    </SearchScreen>
</template>

<script>
import { datetimeToLocalTimeFormatter, currencyFormatter } from '../../components/Common/Formatters.js';
import axios from 'axios';
import { downloadFileToBrowser } from '@/components/Common/BrowserDownload.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import SearchScreen from '../../components/SearchScreen.vue';

export default {
    components: { SearchScreen },
    props: {
        customers: Object,
        account: Object
    },
    methods: {
       getTotalRows(value){
          this.totalRows=value
        },
        refreshTable() {
            this.$refs?.searchScreen.refreshSearchResults();
        },
        printScreen(){
            window.print();
        },
        dccFormatter(value) {
            return value.padStart(6, '0'); // Fixes DCCs like 030101 which would otherwise be displayed as 30101
        },        
        async downloadFile(exportFormat)
        {
         let apiUrl = 'StockLedgers/csv';
            this.isBusy = true;
             axios
                .post(
                    apiUrl,
                    this.filters,
                    { responseType: 'blob' }
                )
                  .then(result => {
                    downloadFileToBrowser(
                        result.data,
                        exportFormat == 'pdf' ? `Stock Ledger` : `Stock Ledger.csv`,
                        exportFormat == 'pdf' ? 'application/pdf' : 'application/csv'
                    );
                    this.$toasted.global
                        .app_success(
                            `Stock Ledger report generated successfully.`
                        )
                        .goAway(5000);
                })
                .finally(() => {
                    this.isExporting = false;
                });
        }
    },
    data() {
        return {            
            filterName: 'TransactionsFilter',
            selectedCustomers: [],
            isBusy: false,
            totalRows:0,
            filters: {
                automatic: null,
                searchBy: 'All',
                startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
                endDate: new Date(),
                sortBy: 'createDate',
                sortDesc: true,
                perPage: 50
            },
            filterBy: [
                'All',
                'Entry Type',
                'SKU',
                'Entry Reference',
                'DCC'],
            fields: [            
                {
                    label: 'Location',
                    key: 'locationName',
                    sortable: true
                },
                {
                    label: 'Accounting Date',
                    key: 'accountingDate',
                    sortable: true
                },
                {
                    label: 'User Name',
                    key: 'userName',
                    sortable: true
                },
                {
                    label: 'GL Code',
                    key: 'glCode',
                    sortable: true,
                },
                {
                    label: 'Entry Type',
                    key: 'entryType',
                    sortable: true,
                },
                {
                    label: 'Entry Reference',
                    key: 'entryReference',
                    sortable: true
                },
                {
                    label: 'SKU',
                    key: 'sku',
                    sortable: true,
                },
                {
                    label: 'DCC',
                    key: 'dcc',
                    sortable: true,
                    formatter: this.dccFormatter                    
                },               
                {
                    label: 'SOH Changed',
                    key: 'sohChanged',
                    sortable: true
                },
                {
                    label: 'Retail',
                    formatter: currencyFormatter,
                    key: 'retail',
                    sortable: true,
                    tdClass: 'text-center'
                },
                {
                    label: 'Cost',
                    formatter: currencyFormatter,
                    key: 'cost',
                    sortable: true,
                    tdClass: 'text-center'
                },
                {
                    label: 'Entry Date',
                    key: 'createDate',
                    formatter: datetimeToLocalTimeFormatter,
                    sortable: true,
                    sortByformatted: true
                }
            ],
            refreshSearchResults: false
        };
    },
    mounted : async function() {
        this.filters.locationId = await selectListOptionsDataContext.getSelectedLocationIdAsync();
    },};
</script>
