<template>
    <p-feature-flag
        name="POS Admin" 
        redirect>
        <SearchScreen
            title="Non Merch"
            ref="searchScreen"
            api-destination="nonmerch"
            edit-key="specialItemId" 
            :filters="filters"
            :fields="fields"
            :filterBy="filterBy"
            add-new-button>
        </SearchScreen>
    </p-feature-flag>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
import {
    currencyFormatter,
} from '../../components/Common/Formatters.js';

export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'NonMerchFilter',
            filters: {
                automatic: null,
                perPage: 100,
                sortBy: 'name',
                sortDesc: true,
                searchBy: 'All'
            },
            filterBy: [
                'All'
            ],
            fields: [
                {
                    key: 'name',
                    sortable: true,
                    label: 'Name'
                },
                {
                    key: 'location',
                    sortable: true,
                    label: 'Location'
                },
                {
                    key: 'tax',
                    sortable: true,
                    label: 'Tax'
                },
                {
                    key: 'price',
                    sortable: true,
                    label: 'Price',
                    formatter: (value) => currencyFormatter(value, 'Variable'),
                },
                {
                    key: 'allowDiscounts',
                    sortable: true,
                    label: 'Allow Discounts'
                },
                {
                    key: 'isRefundable',
                    sortable: true,
                    label: 'Is Refundable'
                },
                {
                    key: 'isActive',
                    sortable: true,
                    label: 'Is Active',
                    disabled: true                    
                },                                
                {
                    key: 'specialItemId',
                    hidden: true
                }
            ]
        };
    }
};
</script>